import { clientTypeEnum, permissionEnum } from '../enum'

/**
 * Removed COMPANY_REVENUE & CALENDAR upon request
 * Weihong 9:18 PM
 * @YIWEI YAO
 * on our own product, pls detach "Calendar" and "Revenue" from the existing product for all users other than Admin users.
 */

const financialPermissions = [
  permissionEnum.ASSET_MANAGEMENT,
  permissionEnum.MARKET,
  permissionEnum.MARKET_EPS,
  permissionEnum.MARKET_FINANCIAL_REPORTING_RISK,
  permissionEnum.MARKET_COMPANY_RANKING_ANNUAL,
  permissionEnum.MARKET_COMPANY_RANKING_QUARTERLY,
  permissionEnum.COMPANY_SEARCH,
  permissionEnum.COMPANY_EPS,
  // permissionEnum.COMPANY_REVENUE,
  permissionEnum.COMPANY_RISK_SCORE,
  // permissionEnum.CALENDAR,
  permissionEnum.PORTFOLIO,
  permissionEnum.COMPANY_PEER_GROUP,
  permissionEnum.COMPANY_QUARTERLY_PEER_GROUP,
  permissionEnum.COMPANY_DATA_SOURCE_AND_ANALYTICS,
]

const litigationPermissions = [
  permissionEnum.LITIGATION,
  permissionEnum.MARKET,
  permissionEnum.MARKET_EPS,
  permissionEnum.MARKET_FINANCIAL_REPORTING_RISK,
  permissionEnum.MARKET_COMPANY_RANKING_ANNUAL,
  permissionEnum.MARKET_COMPANY_RANKING_QUARTERLY,
  permissionEnum.COMPANY_SEARCH,
  permissionEnum.COMPANY_EPS,
  // permissionEnum.CALENDAR,
  permissionEnum.PORTFOLIO,
]

const trialPermissions = [
  permissionEnum.ASSET_MANAGEMENT,
  permissionEnum.MARKET,
  permissionEnum.MARKET_EPS,
  permissionEnum.MARKET_FINANCIAL_REPORTING_RISK,
  permissionEnum.MARKET_COMPANY_RANKING_ANNUAL,
  permissionEnum.MARKET_COMPANY_RANKING_QUARTERLY,
  permissionEnum.COMPANY_SEARCH,
  permissionEnum.COMPANY_EPS,
]

export const getPermissions = (clientType?: clientTypeEnum | string) => {
  if (clientType === clientTypeEnum.financial) return financialPermissions
  else if (clientType === clientTypeEnum.litigation)
    return litigationPermissions
  else if (clientType === clientTypeEnum.trial) return trialPermissions

  if (clientType === clientTypeEnum.developer)
    return [...Object.keys(permissionEnum)] as permissionEnum[]
  return []
}
